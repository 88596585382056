body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 9999;
}

.no-scroll {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  user-select: none;
}

/* temp fix */
/* bootstarp modal won't show correctly */
/* something is overwrite this class */
/* TODO need to figure out later */
.fade {
  opacity: 1;
}

.sd_button {
  position: relative;
  display: inline-block;
  padding: 0 30px;
  line-height: 48px;
  border-radius: 2px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: white .25s ease-in-out,color .25s ease-in-out,box-shadow .25s ease-in-out;
}

.sd_button_primary {
  background: #02b7cc;
  color: #fff;
}

.sd_button_primary:hover {
  background: #02b7cccc;
}

.sd_html {
  font-family: DIN Next,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 1.87;
  color: #677279;
  background: #fff;
}